module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":99216240,"webvisor":true,"trackHash":true,"afterBody":true,"defer":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icons/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c92ba6df8678e1012ec7b3f9e6ec4df1"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
